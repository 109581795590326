import React, { useContext } from "react"
import { Link } from "gatsby-plugin-react-intl"
import { Media } from "../../../utils/Media"
import CartContext from "../../../context/CartContext"
import { useIntl } from "gatsby-plugin-react-intl"

// React components imports
import AmountInput from "../../AmountInput"

// Styled components imports
import Wrapper from "./Wrapper"
import Container from "./Container"
import Title from "./Title"
import Description from "./Description"
import Availability from "./Availability"
import IconWrapper from "./IconWrapper"
import CrossIcon from "../../../shared-styled-components/CrossIcon"

const Product = ({ data }) => {
  const intl = useIntl()
  const { updateLineItem, removeLineItem } = useContext(CartContext)
  const changeAmount = amount => {
    updateLineItem({
      variantId: data.variant.id,
      quantity: amount,
    })
  }

  const getAvailability = () => {
    if (data.variant.available) {
      return <>{intl.formatMessage({ id: "product.stock.available" })}</>
    } else if (!data.variant.available) {
      if (data.variant.available === undefined) {
        return ""
      } else {
        return <>{intl.formatMessage({ id: "product.stock.not_available" })}</>
      }
    }
    return
  }

  const removeItemFromCart = () => {
    removeLineItem(data.id)
    // Remove from cart gtag event
    if (typeof window.gtag !== "undefined") {
      const productPrice = data.variant.priceV2.amount
      const productCurrency = data.variant.priceV2.currencyCode
      const productObj = {
        item_id: data.id,
        item_name: data.title,
        currency: productCurrency,
        price: productPrice,
        quantity: data.quantity,
      }
      window.gtag("event", "remove_from_cart", {
        currency: productCurrency,
        value: productPrice,
        items: [productObj],
      })
    }
  }
  return (
    <Wrapper>
      <Container>
        <Link to={`/produkt/${data.variant.product.handle}`}>
          <img src={data.variant.image.src} alt={data.variant.image.altText} />
        </Link>
        <div style={{ justifySelf: "start" }}>
          <Link to={`/produkt/${data.variant.product.handle}`}>
            <Title>
              {data.customAttributes[1]?.value
                ? data.customAttributes[1].value
                : data.title}
            </Title>
          </Link>
          {data.variant.title !== "Default Title" && (
            <Description>{data.variant.title}</Description>
          )}
          {/* <Description>
            {data.customAttributes[0].value}
          </Description> */}
        </div>
        <div>
          <Media lessThan="largeDesktop">
            <div>Dostupnost</div>
          </Media>
          <Availability available={data.variant.available}>
            {getAvailability()}
          </Availability>
        </div>
        <AmountInput
          align="end"
          amount={data.quantity}
          changeAmount={changeAmount}
        />
        <div>
          <Media lessThan="largeDesktop">
            <div>Cena za kus</div>
          </Media>
          <div>
            {data.variant.priceV2.amount} Kč{" "}
            <span style={{ color: "#959595" }}>/ ks</span>
          </div>
        </div>
        <div style={{ justifySelf: "end", textAlign: "right" }}>
          <div style={{ display: "inline-block", verticalAlign: "middle" }}>
            <Media lessThan="largeDesktop">
              <div>Součet</div>
            </Media>
            <div>
              {(data.variant.priceV2.amount * data.quantity).toFixed(2)} Kč
            </div>
          </div>
          <IconWrapper>
            <CrossIcon size="24" red onClick={removeItemFromCart} />
          </IconWrapper>
        </div>
      </Container>
    </Wrapper>
  )
}

export default Product
