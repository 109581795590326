import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

//React components imports
import Layout from "../components/Layout"
import Cart from "../components/Cart"
import Breadcrumbs from "../components/Breadcrumbs"

//Styled components imports
import Title from "../shared-styled-components/Title"

const CartPage = ({ location }) => {
  const intl = useIntl()

  return (
    <Layout subtitle={intl.formatMessage({ id: "cart.title" })}>
      <Breadcrumbs
        data={{ path: location.pathname.replace("/en", ""), breadEnd: "Košík" }}
      />
      <Title>{intl.formatMessage({ id: "cart.title" })}</Title>
      <Cart />
    </Layout>
  )
}

export default CartPage
