import styled from "styled-components"

const Description = styled.div`
  font-size: 12px;

  @media (min-width: 1400px) {
    font-size: 14px;
  }
`

export default Description
