import styled from "styled-components"

const Summary = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 24px;
    max-width: 450px;
    margin-left: auto;
  }
`

export default Summary
