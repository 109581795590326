import styled from "styled-components"

const Container = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr;
  grid-gap: 10px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-template-columns: 150px 1fr;
    grid-gap: 15px;
    align-items: center;
  }
  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    grid-template-columns: 150px 1fr auto 75px 125px 125px;
    grid-gap: 15px;
    align-items: center;
    justify-items: end;
  }
  @media (min-width: 1400px) {
    grid-template-columns: 200px 1fr auto 75px 120px 125px;
    grid-gap: 20px;
  }
`

export default Container
