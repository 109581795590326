import styled from "styled-components"

const IconWrapper = styled.div`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-left: 5px;
  cursor: pointer;
`

export default IconWrapper
