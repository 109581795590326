import styled from "styled-components"

const Wrapper = styled.div`
  font-size: 16px;
  margin-bottom: 30px;

  @media (min-width: 1400px) {
    font-size: 18px;
  }
`

export default Wrapper
