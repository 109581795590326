import React, { useContext, useState } from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import CartContext from "../../context/CartContext"

//React components imports
import Product from "./Product"

//Styled components imports
import Wrapper from "./Wrapper"
import Summary from "./Summary"
import Buttons from "./Buttons"
import Link from "./Link"
import EmptyText from "./EmptyText"
import Button from "../../shared-styled-components/Button"
import Note from "./Note"

const Cart = () => {
  const intl = useIntl()
  const { checkout, updateCartNote } = useContext(CartContext)
  const [note, setNote] = useState("")

  function updateNote(e) {
    setNote(e.target.value)
  }

  return (
    <Wrapper>
      <div>
        {checkout?.lineItems.map(product => (
          <Product data={product} key={product.id} />
        ))}
      </div>
      {checkout?.lineItems.length ? (
        <>
          <hr style={{ borderColor: "#707070" }} />
          <Summary>
            <div> {intl.formatMessage({ id: "cart.total" })}</div>
            <div style={{ marginRight: "28px" }}>
              {checkout?.totalPrice.amount || 0} Kč
            </div>
          </Summary>
          <Note note={note} handleOnChange={updateNote} />
        </>
      ) : (
        <EmptyText>{intl.formatMessage({ id: "cart.empty" })}</EmptyText>
      )}
      <Buttons>
        {checkout?.lineItems.length ? (
          <Button
            onClick={() => {
              updateCartNote(note)
              window.location.href = checkout.webUrl
            }}
          >
            {intl.formatMessage({ id: "cart.continue" })}
          </Button>
        ) : null}
        <Link to="/produkty">
          &lt; {intl.formatMessage({ id: "cart.continue" })}{" "}
        </Link>
      </Buttons>
    </Wrapper>
  )
}

export default Cart
