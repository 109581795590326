import styled from "styled-components"
import { Link as  GatsbyLink } from "gatsby"

const Link = styled(GatsbyLink)`
 &:hover{
   color: ${props=>props.theme.colors.text.darkGrey}
 }
`

export default Link
