import styled from "styled-components"

const Buttons = styled.div`
  margin: 25px 0;
  display: grid;
  grid-gap: 15px;
  justify-content: center;

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin-top: 45px;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;

    button {
      grid-column: 2/3;
    }
  }
`

export default Buttons
