import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import styled from "styled-components"

const WrapperStyled = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  margin-top: 36px;
`
const LabelStyled = styled.span`
  width: fit-content;
  height: fit-content;
  font-size: 18px;
  color: white;
`
const TextAreaStyled = styled.textarea`
  width: 100%;
  height: 85px;
  padding: 8px;
  font-size: 14px;
  background: transparent;
  color: white;
  resize: none;
  outline: none;
  border: 1px solid white;
`
const Note = ({ handleOnChange, note }) => {
  const intl = useIntl()

  return (
    <WrapperStyled>
      <LabelStyled>
        {intl.formatMessage({
          id: "order_note",
        })}
      </LabelStyled>
      <TextAreaStyled value={note} onChange={handleOnChange}></TextAreaStyled>
    </WrapperStyled>
  )
}

export default Note
